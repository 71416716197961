body {
  margin: 0;
  font-family: tahoma, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0e141b

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.couch {
  width: 300px;
  height: 100%
}

.title-div {
  display: flex;
  text-align: center;
  font-size: 45px;
  height: 50vh;
  flex-direction: column;
  justify-content: center;
  padding: 0px 35px;
}

.portfolio-div {
  display: flex;
  max-width: 1200px;
  width: 85vw;
  height: auto;
  background: #0e141b;
  margin: 25px auto;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}


.project-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 550px;
  width: 40%;
  min-width:350px;
  min-height: 550px;
  height: auto;
  background-color: oldlace;
  border-radius: 15px;
  margin: 15px;
  padding: 0px 10px 0px 10px;
  border: 5px solid #cd647b;
}

.project-div:hover {
  border: 5px solid #ff007a
}

.container {
  padding: 25px;
}

.info-div {
  padding: 0px 35px;
}

.info-div h4 {
  font-size: 20px;
  text-align: center;
  color: white;
}

.project-div p {
  text-align: center;
}

.photo-div {
  width: 90%;
  display: block;
  height: 250px;
  margin: 0 auto;
}

img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}


.links-div {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 10px auto;
}

h4 {
  text-align: center;
}

.cv-img {
  height: 50px;
  width: 40px;
  filter: none;
}

.cv-img:hover {
  filter: invert(46%) sepia(68%) saturate(2477%) hue-rotate(317deg) brightness(101%) contrast(101%)
}

.icons-div {
  padding: 15px 0px 15px;
    margin: 0 auto;
    padding: auto;
    display: flex;
    justify-content: space-between;
    width: 300px;
}

.kiaora {
  color: white;
}

.red {
  color: #ff5277
}

.project-title {
  color: #ff5277;
  font-weight: bold;
  font-size: 20px;
  padding: 20px 0px;
  display: block;
}


img:hover {
  .project-title {
    color: black
  }
}

li {
  list-style: none;
  color: white;
  padding-left: 25px;
  font-weight: bold;
}

li:hover {
  color: #ff5277;
  cursor: pointer;
}

.navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  padding-top: 15px;
  position: sticky;
  top: 0px;
  background-color: #0e141b6c;
  z-index: 1;
}

.nav-list ul {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  padding: 0;
}

.nav-list ul li {
  list-style: none;
  color: white;
  padding-left: 25px;
  font-weight: bold;
}

.nav-list ul li:hover a {
  color: #ff5277;
  text-decoration: none; /* Removes the underline on hover */
}

.nav-list ul li a {
  text-decoration: none; /* Removes underline by default */
  color: inherit; /* Inherit parent color (white) */
}

.name-div p {
  color: white;
  font-weight: bold;
}

.icons-div #github:hover {
  cursor: pointer;
  stroke: #ff5277;
}

.icons-div #linkedin:hover {
  cursor: pointer;
  fill: #ff5277;
}

.white {
  color: white;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  width: 85vw;
  max-width: 1200px;
  margin: auto;
}

.sourcecode a, .sourcecode a:visited {
  display: flex;
  justify-content: space-around;
  width: 130px;
  align-items: center;
  text-decoration: none;
  color: black;
  border: 2px solid #ff5277;
  border-radius: 15px;
  padding: 5px;
}

.weblink a, .weblink a:visited {
  display: flex;
  justify-content: space-around;
  width: 100px;
  align-items: center;
  text-decoration: none;
  color: black;
  border: 2px solid #ff5277;
  border-radius: 15px;
  padding: 5px 9px 5px 5px;
}

.weblink a:hover, .sourcecode a:hover{
  border: 2px solid black;
}

.sourcecode, .weblink {
  padding:0px 10px;
}

@media only screen and (max-width: 768px) {
  .navbar {
    visibility:hidden;
    position: absolute
  }
  .title-div h4 {
    font-size: 35px;
  }
  .title-div h2 {
    font-size: 40px;
  }
  .info-div h4 {
    font-size: 20px;
  }
  .title-div {
    padding-top: 25px;
  }
}